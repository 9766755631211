// 搜索类型的 Select 下拉框列表
const searchTypeList = [
  {
    value: 1,
    label: "订单号",
  },
  {
    value: 2,
    label: "品号",
  },
  {
    value: 3,
    label: "品名",
  },
  {
    value: 4,
    label: "机台号",
  },
];

export {
  searchTypeList
}
