import axios from "axios";

/**
 * 获取指示单报表列表
 */
 export const fetchSheetReportList = params => {
    return axios({
      url: "/dtsum/zongtong/greycloth/greyClothController/badClothInquire",
      method: "GET",
      params
    });
  };

