<template>
  <div id="page-grey-cloth-sheet" class="container">
    <!-- 顶部筛选 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <span style="margin-right: 10px">检验日期</span>
        <DatePicker
          style="width: 220px"
          type="daterange"
          placeholder="请选择日期范围"
          :clearable="false"
          v-model="listParam.filterDateRange"
          @on-change="handleDateRangeChange"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">挡车工</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.blockWorker"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">检验员</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.testPerson"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">疵点类型</span>
        <Select v-model="listParam.defectType" style="width: 120px" @on-change="getTableListData">
          <Option value="all" key="all">全部</Option>
          <Option v-for="(item, index) in defectTypeList" :value="item.defectType" :key="index">
            {{ item.defectType }}</Option
          >
        </Select>
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">好布重量 ></span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.goodClothBigger"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <Select v-model="listParam.searchType" style="width: 120px">
          <Option v-for="item in searchTypeList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.searchText"
          @keyup.enter.native="getTableListData"
          style="width: 200px"
        />
      </div>
    </div>
    <div class="common-action-block">
      <div class="common-action-item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="common-action-item" @click="exportTableList">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
      <div class="common-action-item" @click="syncTableData">
        <i class="iconfont iconicon-tongbu"></i>
        同步
      </div>
    </div>

    <!-- 表格 -->
    <Table border :loading="loading" :columns="tableColumns" :data="tableData">
      <!-- <template slot-scope="{ row }" slot="finishRate">
        <span>{{ Number(row.finishRate) * 100 + "%" }}</span>
      </template> -->
    </Table>

    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
  </div>
</template>

<script>
import { fetchSheetReportList } from "./api";
import { searchTypeList } from "./constant";
export default {
  name: "clothQuery",
  data() {
    return {
      blockWorkerList: [], // 形如 [{name: 'aaa'},{name:'bbb'}]，后端返过来就长这样
      testPersonList: [],
      defectTypeList: [],
      // 首页表格配置
      loading: false,
      listParam: {
        startDate: this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"), // 如 '2021-07-16'，精确到天
        endDate: this.moment(new Date()).format("YYYY-MM-DD"),
        filterDateRange: [
          this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"),
          this.moment(new Date()).format("YYYY-MM-DD"),
        ], // 前端用，
        blockWorker: "", // 挡车工姓名，默认 "" 搜索全部
        defectType: "all", // 疵点类型
        searchType: 1, // 1 订单号，2 品号，3 品名，4 机台号
        searchText: "", // 根据searchType填写相应内容
        testPerson: "", // 检验员
        goodClothBigger: "", // 好布重量大于
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      tableColumns: [
        {
          title: "检验日期",
          key: "testDate",
          minWidth: 180,
        },
        {
          title: "坯布条码",
          key: "greyClothCode",
          minWidth: 120,
        },
        {
          title: "订单号",
          key: "orderNo",
          minWidth: 160,
        },
        {
          title: "品号",
          key: "productNo",
          minWidth: 120,
        },
        {
          title: "品名",
          key: "productName",
          minWidth: 160,
        },
        {
          title: "机台号",
          key: "machineNo",
          minWidth: 160,
        },
        {
          title: "挡车工",
          key: "blockWorker",
          minWidth: 120,
        },
        {
          title: "疵点",
          key: "defect",
          minWidth: 120,
        },
        {
          title: "次品数量",
          key: "badProductNum",
          minWidth: 120,
        },
        {
          title: "好布重量",
          key: "goodProductNum",
          minWidth: 120,
        },
        {
          title: "米数",
          key: "meterNum",
          minWidth: 120,
        },
        {
          title: "扣钱",
          key: "deductMoney",
          minWidth: 120,
        },
        {
          title: "检验员",
          key: "testPerson",
          minWidth: 120,
        },
      ],
      searchTypeList, // 首页——搜索类型的 Select 下拉框列表
      listDataTotal: 0, // 分页器数据总数
    };
  },
  created() {
    this.getTableListData();
  },
  methods: {
    handleDateRangeChange(formatDate, DateObj) {
      this.listParam.startDate = formatDate[0];
      this.listParam.endDate = formatDate[1];

      this.getTableListData();
    },
    handleDateChange(formatDate, DateObj) {
      this.listParam.deliveryDate = formatDate;
      this.getTableListData();
    },

    getTableListData() {
      const { filterDateRange, pageNum, pageSize, ...reqParamObj } = this.listParam;
            if (this.listParam.goodClothBigger !== "") {
        this.listParam.goodClothBigger = Number(this.listParam.goodClothBigger);
      }
       if (typeof this.listParam.goodClothBigger === "number" && !isNaN(this.listParam.goodClothBigger)) {
        reqParamObj.goodClothBigger = this.listParam.goodClothBigger;
      }
      fetchSheetReportList({ pageNum, pageSize, params: { ...reqParamObj } })
        .then((res) => {
          if (res.data.success === 1) {
            const { list, blockWorkerList, testPersonList, defectTypeList, pageTotalLength } =
              res.data.body;
            this.blockWorkerList = blockWorkerList; // res的name 形如 [{name: 'aaa'},{name:'bbb'}]
            this.testPersonList = testPersonList;
            this.defectTypeList = defectTypeList;
            this.tableData = list;
            this.listDataTotal = pageTotalLength;
            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 同步表格数据
    syncTableData() {
      this.loading = true;
      this.axios({
        url: "/dtsum/zongtong/greycloth/greyClothController/synchronizeBadClothInquire",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.getTableListData();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },

    exportTableList() {
      const { pageNum, pageSize, ...otherParam } = this.listParam;
      const { filterDateRange, ...data } = otherParam;
      const dataStr = this.qs.stringify(data);
      console.log(`${this.baseUrl}/dtsum/zongtong/greycloth/greyClothController/badClothInquireExport?${dataStr}`);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/greycloth/greyClothController/badClothInquireExport?${dataStr}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";

.container {
}
</style>
